@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";


.school-list {
  height: 100%;
  overflow-y: auto;

  .search-box {
    position: relative;

    input {
      padding-right: 2rem;
    }

    .delete {
      position: absolute;
      right: 0.5rem;
    }
  }

  @include mobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}