.school-map {
    flex: 1;
    width: 100%;
    height: 100%;
}

.school-map-container {
    position: relative;
    height: 100%;
}

.school-counter {
    position: absolute;
    top: .5rem;
    left: 0.5rem;
}

.school-info {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    width: calc(100% - 2rem);
}
