.multi-checkbox {

  .checkbox input {
    margin-right: .5rem;
  }

  label.checkbox {
    margin-right: 1rem;
  }

}
