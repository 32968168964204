.about-page {
  width: 100%;
  min-height: 100%;

  .content {
    max-width: 750px;
    margin-bottom: 1.75rem;

    h3 {
      margin-bottom: 1rem;
    }
  }

  .e:before {
    content: attr(data-domain) "\0040" attr(data-user);
    unicode-bidi: bidi-override;
    direction: rtl;
  }

  .e { font-style: italic; }

}