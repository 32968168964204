@import '~bulma/sass/utilities/initial-variables.sass';


.range-slider {

  .MuiSlider-markLabel {
    font-size: 0.5rem;
    font-family: inherit;
  }

  .MuiSlider-valueLabel {
    font-size: 0.6rem;
    font-family: inherit;
  }

  .MuiSlider-thumb {
    background-color: $blue;
  }

  .MuiSlider-root {
    color: $blue;
  }

  .MuiSwitch-colorPrimary.Mui-checked {
    color: $blue;
  }

  .MuiSwitch-root.MuiSwitch-sizeSmall {
    height: 20px;
    width: 36px;

    .MuiSwitch-thumb {
      width: 12px;
      height: 12px;
    }
  }

  .MuiFormControlLabel-label {
    font-size: 0.75rem;
  }

}