.open-street-map {
  width: 100%;
  height: 100%;

  .ol-zoom {
    right: .5em;
    left: auto;
  }

  .ol-rotate {
    top: 70px;
  }

  .ol-attribution {
    font-size: .75em;
  }
}