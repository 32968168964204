@import '~bulma/sass/utilities/initial-variables.sass';


.school-card {

  &.box {
    border-radius: 4px;
  }

  .is-6_5 {
    font-size: 0.85rem;
  }

  hr {
    background-color: $grey-lighter;
    height: 1px;
  }

  .toggle:hover {
    cursor: pointer;
  }

  .school-card-header {
    .level-left {
      min-width: 0;
      flex-shrink: 1;
      flex-basis: 100%;
      padding-right: 0.5rem;
    }
  }

  .school-card-subheader.level {
    justify-content: normal;

    .level-item {
      flex-grow: 0 !important;
      justify-content: flex-start;
    }

    .school-card-subheader-city {
      flex-shrink: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .school-card-subheader-fraser-summary {
      flex-grow: 1 !important;
      justify-content: flex-end;
    }
  }

  .school-card-footer {

    display: flex;
    position: relative;

    .level-left {
      min-width: 0;
      flex-shrink: 1;
      flex-basis: 100%;
      padding-right: 0.5rem;
      
      .school-card-footer-item {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .level-left + .level-right {
      margin-top: 0;
    }

    .school-card-footer-image {
      position: absolute;
      bottom: 4rem;
      right: 1rem;
      padding: .5rem;

      img {
        max-width: 500px;
        max-height: 300px;

        &.narrow {
          max-width: 250px;
          max-height: 200px;
        }
      }

    }
  }

  &.narrow {

    nav.level.school-card-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      margin-bottom: -0.5rem !important;

      .level-item {
        margin-bottom: 0.5rem;
      }
    }
  }

  .seismic-icon > path {
    fill: $orange;
  }

  .closure-icon > path {
    fill: $grey-lighter;
  }

}