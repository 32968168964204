@charset "utf-8";
@import "~bulma/bulma.sass";

* { 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root { height: 100%; }

body::-webkit-scrollbar {
    display: none;
}

.app {
  display: flex;
  width: 100%;
  height: 100%;
}

.map-wrapper, .list-wrapper {
  width: 100%;
  height: 100%;
}
