@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";


.control-panel {
  min-width: 350px;
  height: 100%;
  flex: 0;
  overflow-y: auto;
  background-color: #f0f0f0;
  border-right: solid 1px #e3e3e3;

  &.collapsed {
    width: 50px;
    min-width: 50px;

    @include until($desktop) {
      width: 0px;
      min-width: 0px;
    }
  }

  .column {
    position: relative;
  }

  .control-panel-footer {
    width: 100%;
    max-height: 3rem;
    font-size: 0.85rem;
  }

  .info-marker {
    cursor: pointer;
  }

  .toggle {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
  }

  .toggle:hover {
    cursor: pointer;
  }

  @include until($desktop) {
    position: absolute;
    z-index: 10;
    max-width: 350px;
  }

  @include mobile {
    max-width: 100%;
  }

}

.mobile-toggle {
  display: none;

  @include until($desktop) {
    display: block;
    position: fixed;
    top: 2.5rem;
    left: 1.5rem;
    z-index: 5;

    &.mobile-toggle-listview {
      top: auto;
      bottom: 2.5rem;
    }
  }
}
